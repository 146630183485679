<template>
  <v-col cols="12" :sm="currentSize.sm" :md="currentSize.md">
    <v-card flat>
      <v-app-bar dense flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-title class="text-h6 pl-0">{{
          $t('dashboard.widgets.calendar')
        }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small icon @click="resize({ type: 'calendar' })" v-on="on">
              <v-icon>mdi-arrow-expand-horizontal</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('dashboard.resize') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small icon class="drag-widget" v-on="on">
              <v-icon>mdi-drag</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('dashboard.move') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small icon @click="close({ type: 'calendar' })" v-on="on">
              <v-icon style="cursor: pointer">mdi-close</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('dashboard.remove') }}</span>
        </v-tooltip>
      </v-app-bar>

      <v-app-bar dense flat color="rgba(0, 0, 0, 0)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon @click="type = 'month'" v-bind="attrs" v-on="on">
              <v-icon>mdi-calendar-month</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('dashboard.calendar.monthView') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon @click="type = 'week'" v-bind="attrs" v-on="on">
              <v-icon>mdi-calendar-week</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('dashboard.calendar.weekView') }}</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-toolbar-title class="text-h6 pl-0">{{ dateLabel }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn small icon @click="prev">
          <v-icon style="cursor: pointer">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn small icon @click="next">
          <v-icon style="cursor: pointer">mdi-chevron-right</v-icon>
        </v-btn>
      </v-app-bar>

      <v-sheet height="500">
        <v-calendar
          ref="calendar"
          v-model="focus"
          :weekday-format="weekday"
          :month-format="month"
          color="secondary"
          :events="visibleEvents"
          :event-color="getEventColor"
          :event-more-text="
            $tc('dashboard.calendar.moreEvents', '{0}', { count: '{0}' })
          "
          :type="type"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @mouseenter:event="openEventPopup"
          @mouseleave:event="closeEventPopup"
          @change="updateRange"
        ></v-calendar>
      </v-sheet>
    </v-card>
    <v-tooltip
      v-model="popupVisible"
      :position-x="popupPositionX"
      :position-y="popupPositionY"
      right
    >
      <span>{{ popupContent }}</span>
    </v-tooltip>
  </v-col>
</template>

<script>
import widgetMixin from '@/mixins/widgets';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Calendar',
  mixins: [widgetMixin],
  data: () => ({
    focus: new Date().toISOString(),
    type: 'month',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    startDate: null,
    endDate: null,
    popupVisible: false,
    popupContent: '',
    popupPositionX: 0,
    popupPositionY: 0,
    events: [],
    colors: ['orange', 'pink lighten-2', 'red lighten-1', 'green', 'orange', 'orange']
  }),
  computed: {
    ...mapGetters({
      updated: 'dashboard/getDatesUpdated'
    }),

    dateLabel() {
      var currentDate = new Date(this.focus);
      return (
        this.$t(`dashboard.months.${currentDate.getMonth() + 1}`) +
        ` ${currentDate.getFullYear()}`
      );
    },

    visibleEvents() {
      if (!this.startDate || !this.endDate) {
        return [];
      }
      return this.calendarEvents.filter(
        (x) => x.start >= this.startDate || x.end <= this.endDate
      );
    },

    calendarEvents() {
      const offset = new Date().getTimezoneOffset() * 60000;

      if (this.events === null || this.events.length === 0) {
        return [];
      }

      return this.events.map((x) => ({
        name: this.$t(`dashboard.dateTypes.${x.type}`),
        start: Date.parse(x.dateUtc) - offset,
        end: Date.parse(x.dateUtc) - offset + (x.type == 1) * 90 * 60000,
        color: this.colors[x.type - 1],
        timed: x.type === 1,
        processId: x.processId,
        feedbackId: x.feedbackId,
        processType: x.processType,
        detailedName: this.getDetailedName(x),
        talkPartner: `${x.talkPartnerFirstName} ${x.talkPartnerLastName}`
      }));
    }
  },
  watch: {
    async updated(value) {
      if (value) {
        this.events = await this.getDates();
      }
    }
  },
  async mounted() {
    this.$refs.calendar.checkChange();
    this.events = await this.getDates();
  },
  methods: {
    ...mapActions({
      getDates: 'dashboard/getDates'
    }),

    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },

    getDetailedName(event) {
      if (event.type === 5 || event.type === 6) {
        return `${this.$t(`dashboard.dateTypes.${event.type}`)}`;
      } else {
        return `${this.$t(`feedbacks.processTypes.${event.processType}`)}-${this.$t(`dashboard.dateTypes.${event.type}`)}`;
      }
    },

    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async showEvent({ nativeEvent, event }) {
      nativeEvent.stopPropagation();

      if (event?.feedbackId) {
        const eventDate = new Date(event.start);
        const now = new Date();
        if (eventDate > now) {
          return;
        }

        this.$router.push({
          name: 'FeedbackForm',
          params: { id: event.feedbackId }
        });
      } else if (event?.talkroundId) {
        // TODO: Implement after clarification
        console.log(event.talkroundId);
      } else {
        // TODO: Pdp review
        console.log(event.processId);
      }
    },

    openEventPopup({ nativeEvent, event }) {
      nativeEvent.stopPropagation();
      if (event.feedbackId) {
        this.popupPositionX = nativeEvent.clientX;
        this.popupPositionY = nativeEvent.clientY;

        var talkRelation = event.processType === 5 || event.processType === 6 ? this.$t('dashboard.talkRelation.pdc') : this.$t('dashboard.talkRelation.other');
        this.popupContent = `${event.detailedName} ${talkRelation} ${event.talkPartner}`;
        this.popupVisible = true;
      }
    },

    closeEventPopup({ nativeEvent }) {
      nativeEvent.stopPropagation();
      // TODO: Fix popup vanishing when quickly switching between two events
      this.popupVisible = false;
    },

    weekday(date) {
      return this.$t(`dashboard.daysShort.${date.weekday + 1}`);
    },

    month(date) {
      return this.$t(`dashboard.monthsShort.${date.month}`);
    },

    updateRange({ start, end }) {
      this.startDate = new Date(`${start.date}T00:00:00`);
      this.endDate = new Date(`${end.date}T23:59:59`);
    }
  }
};
</script>

<style></style>
