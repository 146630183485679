<template>
  <v-col cols="12" :sm="currentSize.sm" :md="currentSize.md">
    <v-card flat class="processStatsWidget">
      <v-app-bar dense flat>
        <v-toolbar-title class="text-h6 pl-0">
          {{ processName }} {{ $t('dashboard.widgets.processStatus') }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              icon
              @click="
                resize({ type: 'processStats', processType: processType })
              "
              v-on="on"
            >
              <v-icon>mdi-arrow-expand-horizontal</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('dashboard.resize') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small icon class="drag-widget" v-on="on">
              <v-icon>mdi-drag</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('dashboard.move') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              icon
              @click="close({ type: 'processStats', processType: processType })"
              v-on="on"
            >
              <v-icon style="cursor: pointer">mdi-close</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('dashboard.remove') }}</span>
        </v-tooltip>
      </v-app-bar>

      <v-card-text>
        <div>
          <div class="text-center text-h4 d-block">{{ processes.length }}</div>
          <div class="text-center text-h6 d-block mb-4">
            {{ processName }} {{ $t('feedbacks.feedbacks') }}
          </div>

          <!-- <div class="text-center text-h6 d-block mb-2">Fortschritt</div> -->
          <div class="text-center mb-6" v-if="hasClosedStatus">
            <v-progress-circular
              :size="100"
              :width="12"
              :value="percentageClosed"
              color="secondary"
            >
              <strong>{{ percentageClosed }}%</strong>
            </v-progress-circular>
          </div>
          <!-- <div class="text-center text-h6 d-block mb-4">abgeschlossen</div> -->
          <template v-for="(status, index) in processStatuses">
            <div :key="`${status} ${index}`" class="mb-2">
              <span>
                <v-icon class="mr-2">{{ getIcon(status) }}</v-icon>
                {{
                  directCountsByStatus[status] + indirectCountsByStatus[status]
                }}
                {{ $t(`dashboard.status.${processType}.${status}`) }}
              </span>

              <router-link
                v-if="directCountsByStatus[status] > 0"
                :to="getRoute(status, false)"
              >
                ({{ directCountsByStatus[status] }}
                {{ $tc('dashboard.direct', directCountsByStatus[status]) }},
              </router-link>
              <span v-else>
                ({{ directCountsByStatus[status] }}
                {{ $tc('dashboard.direct', directCountsByStatus[status]) }},
              </span>

              <router-link
                v-if="indirectCountsByStatus[status] > 0"
                :to="getRoute(status, true)"
              >
                {{ indirectCountsByStatus[status] }}
                {{ $tc('dashboard.indirect', indirectCountsByStatus[status]) }})
              </router-link>
              <span v-else>
                {{ indirectCountsByStatus[status] }}
                {{ $tc('dashboard.indirect', indirectCountsByStatus[status]) }})
              </span>
            </div>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import widgetMixin from '@/mixins/widgets';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'processStats',
  mixins: [widgetMixin],
  props: {
    processType: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      processes: []
    };
  },

  computed: {
    ...mapGetters({
      updated: 'dashboard/getProcessesUpdated',
      processList: 'dashboard/getProcesses',
      statuses: 'dashboard/getStatuses',
      getStatusMapping: 'workflows/getStatusMapping'
    }),

    processName() {
      return this.$t(`feedbacks.processTypes.${this.processType}`);
    },

    processStatuses() {
      const mapping = this.getStatusMapping.find(
        (x) => x.processType === this.processType
      )?.mapping;
      if (mapping) {
        return Object.keys(mapping).map((x) => parseInt(x));
      }

      return [];
    },

    directCountsByStatus() {
      return this.getCounts(true);
    },

    indirectCountsByStatus() {
      return this.getCounts(false);
    },

    hasClosedStatus() {
      return this.processStatuses?.includes(this.statuses.closedStatus);
    },

    percentageClosed() {
      if (this.processes?.length > 0) {
        const amountClosed = this.processes.filter(
          (x) => x.status === this.statuses.closedStatus
        )?.length;
        return ((100 * amountClosed) / this.processes.length).toFixed(1);
      }
      return 0;
    }
  },

  watch: {
    async updated(value) {
      if (value) {
        this.processes = await this.getProcesses(this.processType);
      }
    },
    async processList() {
      this.processes = await this.getProcesses(this.processType);
    }
  },

  methods: {
    ...mapActions({
      getProcesses: 'dashboard/getProcesses'
    }),

    getRoute(status, indirect) {
      return {
        name: 'FeedbacksAsDonor',
        query: {
          status: status.toString(),
          type: this.processType.toString(),
          indirect: indirect ? '1' : '0'
        }
      };
    },

    routeTo(status, indirect) {
      this.$router.push({
        name: 'FeedbacksAsDonor',
        query: {
          status: status.toString(),
          type: this.processType.toString(),
          indirect: indirect ? '1' : '0'
        }
      });
    },

    getCounts(direct) {
      var processes = {};
      for (const status of this.processStatuses) {
        processes[status] = this.processes.filter(
          (x) => x.status === status && x.isDirect === direct
        ).length;
      }
      return processes;
    },

    getIcon(status) {
      // Could even additionally switch between processType for even more icons...
      switch (status) {
        case this.statuses.openStatus:
          return 'mdi-clipboard-outline';
        case this.statuses.preparationStatus:
          return 'mdi-clipboard-edit-outline';
        case this.statuses.documentationStatus:
        case this.statuses.reviewSetStatus:
          return 'mdi-handshake-outline';
        case this.statuses.closedStatus:
        case this.statuses.reviewElapsedStatus:
          return 'mdi-check-all';
        case this.statuses.reviewRequiredStatus:
          return 'mdi-clipboard-text-clock-outline';
        default:
          return 'mdi-help-circle-outline';
      }
    }
  },

  async mounted() {
    this.processes = await this.getProcesses(this.processType);
  }
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
</style>
